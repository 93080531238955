import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 825.000000 302.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          transform="translate(0.000000,302.000000) scale(0.100000,-0.100000)"
        >
          <path
            d="M2260 1885 c0 -8 11 -25 25 -39 24 -24 25 -31 25 -138 0 -180 12
-400 21 -386 6 8 9 2 9 -19 0 -18 5 -45 11 -60 6 -15 15 -37 19 -48 5 -11 11
-32 14 -46 4 -20 13 -28 35 -31 28 -4 29 -3 35 55 3 33 6 149 6 258 0 188 -1
200 -20 211 -11 7 -20 18 -20 25 0 10 38 13 162 13 143 0 167 -2 204 -21 135
-65 114 -288 -31 -328 -22 -6 -75 -11 -117 -11 l-78 0 0 -105 0 -105 53 0
c154 1 270 41 337 117 61 69 72 122 68 306 -3 148 -4 153 -34 207 -38 68 -114
123 -199 145 -85 22 -525 22 -525 0z"
          />
          <path
            d="M3184 1638 c13 -18 16 -51 16 -155 0 -132 0 -133 23 -129 19 2 22 10
25 49 3 47 3 47 40 47 56 0 110 18 127 43 17 25 20 89 4 119 -17 32 -71 48
-164 48 l-86 0 15 -22z m178 -35 c10 -9 18 -30 18 -49 0 -42 -30 -64 -87 -64
l-43 0 0 65 0 65 47 0 c29 0 53 -6 65 -17z"
          />
          <path
            d="M3450 1651 c0 -6 5 -13 10 -16 6 -4 10 -63 10 -146 0 -78 4 -139 9
-139 32 0 41 14 41 61 l0 49 35 0 c30 0 38 -6 62 -42 26 -41 51 -68 62 -68 3
0 12 4 20 9 11 7 7 19 -24 62 -25 36 -34 54 -25 57 30 10 50 43 50 83 0 53
-13 74 -51 88 -39 13 -199 15 -199 2z m184 -47 c21 -20 20 -68 0 -85 -9 -7
-37 -13 -62 -13 l-47 -1 -3 58 -3 57 50 0 c32 0 55 -6 65 -16z"
          />
          <path
            d="M3804 1650 c-47 -19 -64 -57 -64 -144 0 -59 4 -88 16 -105 48 -68
204 -67 248 2 21 32 25 174 5 205 -8 12 -28 29 -45 37 -34 16 -126 19 -160 5z
m129 -41 c33 -18 37 -28 37 -105 0 -88 -17 -109 -90 -109 -73 0 -90 20 -90
111 0 66 2 74 26 93 30 23 83 27 117 10z"
          />
          <path
            d="M4054 1638 c13 -18 16 -51 16 -156 l0 -133 90 5 c143 9 175 37 175
157 0 117 -41 147 -203 148 l-93 1 15 -22z m200 -39 c23 -18 26 -27 26 -85 0
-36 -4 -74 -10 -84 -12 -22 -61 -40 -111 -40 l-39 0 0 115 0 115 54 0 c39 0
60 -6 80 -21z"
          />
          <path
            d="M4461 1638 c-5 -13 -32 -78 -60 -146 -28 -67 -51 -127 -51 -133 0
-25 46 -2 59 29 l15 33 70 -3 c66 -3 70 -5 86 -33 17 -31 60 -47 60 -22 0 7
-26 76 -58 153 -53 127 -60 139 -85 142 -21 3 -29 -2 -36 -20z m65 -115 c13
-32 24 -59 24 -60 0 -2 -22 -3 -50 -3 -36 0 -50 4 -50 14 0 19 42 117 48 111
3 -2 15 -31 28 -62z"
          />
          <path
            d="M4620 1640 c0 -17 7 -20 50 -20 l49 0 3 -132 3 -133 25 0 25 0 3 133
3 132 49 0 c43 0 50 3 50 20 0 19 -7 20 -130 20 -123 0 -130 -1 -130 -20z"
          />
          <path
            d="M4940 1583 c-18 -43 -45 -109 -61 -148 -35 -84 -35 -85 -6 -85 17 0
28 10 40 35 17 35 17 35 85 35 l68 0 17 -36 c13 -27 22 -35 40 -32 28 4 28 5
-41 171 -53 128 -58 137 -83 137 -24 0 -30 -8 -59 -77z m84 -45 c10 -29 21
-59 23 -65 4 -9 -9 -13 -46 -13 -28 0 -51 3 -51 7 0 13 45 123 50 123 3 0 14
-24 24 -52z"
          />
          <path
            d="M5194 1636 c-21 -18 -28 -32 -28 -61 0 -48 26 -70 102 -85 67 -14 82
-24 82 -56 0 -37 -41 -49 -110 -33 -72 17 -75 17 -75 -5 0 -55 211 -59 230 -4
30 85 3 120 -112 148 -57 13 -77 29 -67 54 8 21 44 30 92 22 71 -12 76 -11 80
6 5 24 -35 38 -108 38 -48 0 -64 -5 -86 -24z"
          />
          <path
            d="M5420 1651 c0 -6 5 -13 10 -16 6 -3 10 -52 10 -110 0 -93 2 -107 23
-130 44 -52 160 -52 205 0 20 24 22 36 22 146 l0 119 -25 0 -25 0 0 -109 c0
-94 -3 -112 -21 -135 -26 -33 -77 -36 -105 -5 -16 17 -20 40 -22 128 -3 65 -8
109 -15 113 -17 11 -57 10 -57 -1z"
          />
          <path
            d="M5736 1633 c10 -20 14 -64 14 -157 l0 -128 90 4 c115 5 144 23 148
91 3 37 -1 48 -19 61 -21 15 -22 16 -6 34 19 21 22 54 7 82 -15 28 -64 40
-161 40 l-88 0 15 -27z m175 -23 c21 -12 25 -40 7 -58 -7 -7 -36 -12 -65 -12
l-53 0 0 40 0 40 46 0 c25 0 54 -5 65 -10z m13 -126 c23 -22 20 -60 -6 -78
-13 -9 -43 -16 -70 -16 l-48 0 0 55 0 55 54 0 c36 0 60 -5 70 -16z"
          />
          <path
            d="M2562 1503 l3 -98 79 1 c73 0 80 2 102 28 24 27 32 83 18 121 -11 29
-60 45 -134 45 l-71 0 3 -97z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
